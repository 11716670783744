import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
     <h1>Strona w budowie.</h1>
     <h4>Zespół fitnij.pl</h4>
    </div>
  );
}

export default App;
